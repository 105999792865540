import React, { ReactElement, useContext } from "react";
import { IUser, ICurrency } from "../../types/types";
import { UserContext } from "../WithUserContext";

interface Props {
  value: number;
  currency?: ICurrency;
}

export default function MoneyValue({ value, currency }: Props): ReactElement {
  const userData = useContext<IUser | undefined>(UserContext);
  const currencyVal = currency || (userData?.settings?.currency as ICurrency) || null;
  return (
    <span>
      {value.toFixed(2)}
      {currencyVal && userData?.settings.premiumFeatures
        ? currencyVal.short
          ? currencyVal.short
          : currencyVal.symbol
        : ""}
    </span>
  );
}
