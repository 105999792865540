import gql from "graphql-tag";
import { ENTRY_FRAGMENT } from "../fragments/entries";

export const ACCOUNT = gql`
  ${ENTRY_FRAGMENT}
  query Account($accountId: Int!, $periodStart: timestamptz!, $periodEnd: timestamptz!) {
    accounts_by_pk(id: $accountId) {
      id
      balance
      icon
      main_account
      entries(
        order_by: { date: desc }
        where: { _and: [{ date: { _gt: $periodStart } }, { date: { _lt: $periodEnd } }] }
      ) {
        ...EntryFragment
      }
      name
      currency
      initial_amount
    }
  }
`;

export const ACCOUNTS_LIST = gql`
  query Accounts($budgetId: Int) {
    accounts(
      order_by: { name: asc }
      where: { _and: [{ account_type: { _eq: "regular" } }, { budget_id: { _eq: $budgetId } }] }
    ) {
      name
      initial_amount
      icon
      id
      balance
      main_account
      currency
    }
  }
`;

export const ACCOUNTS = gql`
  ${ENTRY_FRAGMENT}
  query Accounts($budgetId: Int) {
    accounts(
      order_by: { name: asc }
      where: { _and: [{ account_type: { _eq: "regular" } }, { budget_id: { _eq: $budgetId } }] }
    ) {
      name
      initial_amount
      id
      icon
      balance
      main_account
      currency
      entries(order_by: { date: desc }, limit: 1) {
        ...EntryFragment
      }
    }
  }
`;

export const ACCOUNTS_BY_NAME = gql`
  query AccountsByName($accountName: String, $budgetId: Int) {
    accounts(
      where: { _and: [{ name: { _eq: $accountName } }, { budget_id: { _eq: $budgetId } }] }
    ) {
      id
      name
      main_account
      currency
    }
  }
`;
