import { Classes, HTMLTable } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBudgetEntry } from "../../types/types";
import { ConfirmDialog } from "../common/ConfirmDialog";
import MoneyValue from "../common/MoneyValue";
import Responsive from "../common/Responsive";
import BudgetEntry from "./BudgetEntry";
import MobileBudgetEntry from "./MobileBudgetEntry";

interface IBudgetEntriesProps {
  budgetEntries: IBudgetEntry[];
  budgetSectionId: number;
  onEdit: (budgetEntry: IBudgetEntry) => void;
  onDelete: (budgetEntry: IBudgetEntry) => Promise<any>;
  selectedDate: DateTime;
  totalPlanned: number;
  totalReal: number;
}

export default function BudgetEntries({
  budgetEntries,
  budgetSectionId,
  totalPlanned,
  totalReal,
  onEdit,
  onDelete,
  selectedDate,
}: IBudgetEntriesProps): ReactElement {
  const { t } = useTranslation();

  const [deleteDialogBudgetEntry, setDeleteDialogBudgetEntry] = useState<IBudgetEntry | null>(null);

  const onDeleteCb = useCallback(
    (budgetEntry: IBudgetEntry) => () => {
      setDeleteDialogBudgetEntry(budgetEntry);
    },
    []
  );

  const onCancelDelete = useCallback(() => {
    setDeleteDialogBudgetEntry(null);
  }, []);

  return (
    <div className="py-1">
      <ConfirmDialog
        icon={IconNames.WARNING_SIGN}
        title={t("warnings.delete_entry_warning")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t("warnings.remove_this_budget_entry", {
                categoryName: deleteDialogBudgetEntry?.category?.name,
                value: deleteDialogBudgetEntry?.value,
              }),
            }}
          />
        }
        onCancel={onCancelDelete}
        isOpen={!_.isEmpty(deleteDialogBudgetEntry)}
        onApply={() => {
          deleteDialogBudgetEntry && onDelete(deleteDialogBudgetEntry);
          setDeleteDialogBudgetEntry(null);
        }}
      />
      <Responsive displayIn={["Tablet", "Laptop"]}>
        <HTMLTable
          className="budget-table"
          style={{ width: "100%" }}
          bordered
          compact
          interactive
          striped
        >
          <thead>
            <tr>
              <th className="budget-table-no">{t("labels.no")}</th>
              <th className="budget-table-name">{t("labels.category")}</th>
              <th className="budget-table-amount">{t("labels.real")}</th>
              <th className="budget-table-amount">{t("labels.planned")}</th>
              <th className="budget-table-description">{t("labels.description")}</th>
              <th className="budget-table-actions" />
            </tr>
          </thead>
          <tbody>
            {_.map(budgetEntries, (budgetEntry, idx) => (
              <BudgetEntry
                index={idx + 1}
                key={budgetEntry.id}
                budgetSectionId={budgetSectionId}
                budgetEntry={budgetEntry}
                onEdit={(budgetEntry) => onEdit(budgetEntry)}
                onDelete={onDeleteCb(budgetEntry)}
                selectedDate={selectedDate}
              />
            ))}
            <tr className={`${Classes.CALLOUT} ${Classes.INTENT_PRIMARY}`}>
              <td />
              <td>{t("labels.total")}</td>
              <td>
                <MoneyValue value={totalReal} />
              </td>
              <td>
                <MoneyValue value={totalPlanned} />
              </td>
              <td>
                <MoneyValue value={totalPlanned - totalReal} />
              </td>
              <td />
            </tr>
          </tbody>
        </HTMLTable>
      </Responsive>
      <Responsive displayIn={["Mobile"]}>
        {_.map(budgetEntries, (budgetEntry, idx) => (
          <MobileBudgetEntry
            key={idx}
            budgetEntry={budgetEntry}
            selectedDate={selectedDate}
            onDelete={onDeleteCb(budgetEntry)}
            onEdit={(budgetEntry) => onEdit(budgetEntry)}
          />
        ))}
        <div className="px-3 mt-2">
          <div className="flex flex-row">
            <span className="inline-block mr-2">{t("labels.total_planned")}: </span>
            <MoneyValue value={totalPlanned} />
          </div>
          <div className="flex flex-row">
            <span className="inline-block mr-2">{t("labels.total_real")}: </span>
            <MoneyValue value={totalReal} />
          </div>
          <div className="flex flex-row">
            <span className="inline-block mr-2">{t("labels.difference")}: </span>
            <MoneyValue value={totalPlanned - totalReal} />
          </div>
        </div>
      </Responsive>
    </div>
  );
}
