import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

type Props = {
  icon?: string;
  type: "category" | "account" | "payee";
};

const EntityIcon = ({ icon, type }: Props) => {
  return icon ? (
    <img className="mr-1" src={icon} style={{ width: "20px", height: "20px", display: "inline" }} />
  ) : type === "category" ? (
    <Icon className="mr-1" icon={IconNames.CUBE} />
  ) : type === "account" ? (
    <Icon className="mr-1" icon={IconNames.BANK_ACCOUNT} />
  ) : (
    <Icon className="mr-1" icon={IconNames.THIRD_PARTY} />
  );
};

export default EntityIcon;
