import gql from "graphql-tag";
import { ENTRY_FRAGMENT } from "../fragments/entries";

export const PAYEE = gql`
  ${ENTRY_FRAGMENT}
  query Payee($payeeId: Int!, $periodStart: timestamptz!, $periodEnd: timestamptz!) {
    payees_by_pk(id: $payeeId) {
      id
      entries(
        order_by: { date: desc }
        where: { _and: [{ date: { _gt: $periodStart } }, { date: { _lt: $periodEnd } }] }
      ) {
        ...EntryFragment
      }
      name
      icon
    }
  }
`;

export const PAYEES = gql`
  ${ENTRY_FRAGMENT}
  query Payees {
    payees(order_by: { name: asc }) {
      name
      icon
      id
      entries(order_by: { date: desc }) {
        ...EntryFragment
      }
    }
  }
`;

export const PAYEE_LIST = gql`
  query Payees {
    payees(order_by: { name: asc }) {
      name
      icon
      id
    }
  }
`;
