import React, { useState, useEffect } from "react";

const AnimatedDots = ({ text }: { text: string }) => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const dotsInterval = setInterval(() => {
      console.log(dots);
      setDots((prev) => prev + 1);
    }, 500);
    return () => clearInterval(dotsInterval);
  }, []);

  return (
    <div>
      {text}
      {".".repeat(dots)}
    </div>
  );
};

export default AnimatedDots;
