import gql from "graphql-tag";

export const ENTRY_FRAGMENT = gql`
  fragment EntryFragment on entries {
    category {
      id
      icon
      name
    }
    account {
      name
      icon
      initial_amount
      id
      currency
    }
    payee {
      name
      icon
      id
    }
    id
    entryId: id
    date
    description
    created_at
    type
    value
    transfer
    balance
    user_id
    related_entry
    splitted_entries(where: { deleted_at: { _is_null: true } }) {
      id
      value
      category {
        id
        icon
        name
      }
    }
  }
`;
