import React from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../settings";
import PrivacyPolicyText from "./common/PrivacyPolicyText";
import ContentWithFooter from "./layouts/ContentWithFooter";

const PrivacyPolicy = () => {
  return (
    <ContentWithFooter>
      <Helmet>
        <title>Privacy Policy - {pageTitle}</title>
      </Helmet>
      <PrivacyPolicyText />
    </ContentWithFooter>
  );
};

export default PrivacyPolicy;
