export const DEFAULT_BUDGET_SECTIONS = [
  "Committed expenses",
  "Unexpected expenses",
  "Fun",
  "Loans",
  "Retirement & Savings",
];

export const DEFAULT_CATEGORIES = ["Food", "Home", "Car", "Hobby", "Retirement", "Kids", "Bills"];
export const DEFAULT_PAYEES = ["Biedronka", "Żabka", "Tesco", "Shell"];

export const TRANSFER_KEYS = {
  TRANSFER_TO: "Transfer to",
  TRANSFER_FROM: "Transfer from",
} as const;

export const ENTRY_TYPES = {
  INCOME: "income",
  EXPENSE: "expense",
} as const;

export const FORM_INPUTS = {
  ACCOUNT_SELECT: "ACCOUNT_SELECT",
  CATEGORIES_SELECT: "CATEGORIES_SELECT",
  CURRENCY_SELECTOR: "CURRENCY_SELECTOR",
  DATE_INPUT: "DATE_INPUT",
  ICON_INPUT: "ICON_INPUT",
  MULTIENTRIES: "MULTIENTRIES",
  NUMBER: "NUMBER",
  PAYEE_SELECT: "PAYEE_SELECT",
  RADIO_BUTTONS: "RADIO_BUTTONS",
  RICH_TEXT: "RICH_TEXT",
  SELECT: "SELECT",
  SWITCHER: "SWITCHER",
  TEXT: "TEXT",
  TRANSFER_SWITCH: "TRANSFER_SWITCH",
} as const;

export const COLOR_GRADIENT_RANGE = 50;
