import { FORM_INPUTS } from "../../../utils/constants";
import { isRequired } from "../validators";

const payeeSchema = {
  name: "forms.labels.payee",
  validators: {
    name: isRequired,
  },
  fields: {
    name: {
      required: true,
      label: "forms.labels.payee_name",
      type: FORM_INPUTS.TEXT,
    },
    icon: {
      required: false,
      label: "forms.labels.payee_icon",
      type: FORM_INPUTS.ICON_INPUT,
    },
  },
};

export default payeeSchema;
