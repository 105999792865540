import { useAuth0 } from "@auth0/auth0-react";
import { Button, Classes, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import palmBeach from "../assets/images/palm-beach.jpg";
import pcTabletPhoneMock from "../assets/images/pc-tablet-phone-mockup.png";
import budgetSamples from "../assets/images/sample-budgets-en-2.png";
import AppName from "./AppName";
import Affiliations from "./common/Affiliations";
import { ContactForm } from "./common/ContactForm";
import Quotes from "./common/Quotes";
import Footer from "./Footer";

const Home = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    <div>
      <div className="home-page">
        <div className="inner-content h-full">
          <div className="flex flex-row item-center h-full">
            <div className="flex flex-col justify-center h-full max-w-7xl mx-auto px-3 pt-12">
              <h1 className="text-white mb-12 text-center font-sans text-4xl md:text-8xl font-semibold text-shadow">
                <div>{t("labels.app")}</div>
                <div className="text-10xl md:text-12xl">4</div>
                <div>{t("labels.you")}</div>
              </h1>
              <h2 className="text-white mb-12 text-center font-sans md:text-2xl font-semibold text-shadow">
                {t("app.catchphrase")}
              </h2>

              {!isAuthenticated && (
                <Button
                  rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={40} />}
                  className="text-2xl md:text-3xl px-6 py-3 md:px-32 md:py-6 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                  onClick={() => loginWithRedirect()}
                  intent={Intent.PRIMARY}
                >
                  {t("actions.start")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto my-12 px-4">
        <MainPageBlock title={t("main_page.who_is_this_for.title")}>
          <div className="md:flex flex-row items-center">
            <div className="flex-1 relative h-full">
              <div
                className="w-full h-full absolute top-0 left-0"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(255,255,255,1) 1%, rgba(255,255,255,0) 100%)",
                }}
              />
              <img className="w-full" src={pcTabletPhoneMock} alt="" />
            </div>
            <div className="flex-1 text-xl">
              <Trans i18nKey="main_page.who_is_this_for.text">
                <div>
                  <strong>
                    <AppName />
                  </strong>
                  , your ultimate solution for managing your home budget effortlessly. Take control
                  of your finances, set goals, and make smarter financial decisions with our
                  user-friendly budgeting tools. Start your journey towards financial freedom today
                  for <b>free</b>! Enjoy a 14-day free trial with{" "}
                  <strong>no credit card required.</strong>
                  <div>Explore all our features, risk-free!</div>
                </div>
              </Trans>
            </div>
          </div>
        </MainPageBlock>
        <MainPageBlock title={t("main_page.how_does_it_work.title")}>
          <div className="flex flex-row-reverse">
            <div className="hidden md:flex flex-grow flex-1 max-w-md ml-12">
              <img className="w-full" src={budgetSamples} alt="" />
            </div>
            <div className="flex-grow flex-1">
              <Trans i18nKey="main_page.how_does_it_work.text">
                <div className="bp5-ui-text text-xl">
                  <h3 className={`${Classes.HEADING} mt-12 mb-6`}>Creating Your Budget:</h3>
                  <div>
                    Creating a budget is the first step toward financial stability. Start by listing
                    your sources of income, including your salary, freelance work, or any other
                    income streams. Next, categorize your expenses into fixed costs (like rent or
                    mortgage, utilities, and insurance) and variable costs (such as groceries,
                    entertainment, and dining out). Be honest about your spending habits to create
                    an accurate budget.
                  </div>
                  {/* <h3 className={`${Classes.HEADING} mt-12 mb-6`}>Setting Realistic Goals:</h3>
                  <div>
                    With <AppName />, you can set realistic financial goals tailored to your needs.
                    Whether you want to save for a vacation, pay off debts, or build an emergency
                    fund, our app helps you define achievable goals. Break down your larger goals
                    into smaller, manageable tasks, and our app will provide insights and
                    recommendations to help you stay on track.
                  </div> */}
                  <h3 className={`${Classes.HEADING} mt-12 mb-6`}>Tracking Your Expenses:</h3>
                  <div>
                    Tracking your expenses is crucial for understanding your spending patterns. Use
                    our app to record every purchase, whether it's a cup of coffee or a major
                    purchase. Categorize your expenses to see where your money is going. Our
                    intuitive interface makes it easy to enter transactions on the go, ensuring you
                    never miss a beat in managing your finances.
                  </div>
                  <h3 className={`${Classes.HEADING} mt-12 mb-6`}>Budgeting Tools and Insights:</h3>
                  <AppName /> offers a range of budgeting tools and insightful reports to help you
                  make informed financial decisions. Analyze your spending habits over time, view
                  detailed breakdowns of your expenses, and identify areas where you can cut costs.
                  Our app provides visualizations and graphs to help you understand your financial
                  data, making it easier to plan for the future.
                </div>
              </Trans>
            </div>
          </div>
        </MainPageBlock>
        <MainPageBlock>
          <h1 className="text-4xl text-center mb-4 font-semibold">
            {t("main_page.enjoy_your_money.title")}
          </h1>
          <img src={palmBeach} alt="palm-beach" />
        </MainPageBlock>
        <MainPageBlock>
          <Quotes />
          <Affiliations />
        </MainPageBlock>
        <MainPageBlock>
          <h1 className="mt-40 bp5-heading mx-auto text-center p-2">{t("labels.contact_us")}</h1>
          <ContactForm />
        </MainPageBlock>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

const MainPageBlock = ({
  title,
  children,
  icon,
}: {
  title?: ReactNode;
  icon?: ReactNode | null;
  children: ReactNode;
}) => (
  <div className="my-20 h-100vh flex flex-col justify-center">
    {title && (
      <h1 className="text-4xl mb-4 font-semibold">
        {icon || <Icon className="mr-4 mb-1" icon={IconNames.INFO_SIGN} size={32} />}
        {title}
      </h1>
    )}
    <div>{children}</div>
  </div>
);
