import { ApolloError } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import { GraphQLError } from "graphql";
import _, { isArray } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { TFunction } from "react-i18next";
import data from "../../package.json";
import { ExportOptions } from "../components/common/ExportDialog";
import { Notifications } from "../components/common/notifications";
import { ExportTableOptions } from "../components/entries/Entries";
import i18n from "../i18n";
import {
  IBudgetEntry,
  IBudgetSection,
  ICategory,
  IEntry,
  IIncome,
  ISplittedEntry,
  IUser,
} from "../types/types";
import { calculateSpendings } from "./budgetUtils";

export const isTestEnv = () => {
  return (
    (process.env.NODE_ENV !== "production" &&
      process.env.NODE_ENV !== "staging" &&
      window.location.hostname === "localhost") ||
    process.env.NODE_ENV === "development"
  );
};

export const isStagingEnv = () =>
  window.location.hostname === "budget.constexpr.pl" || process.env.NODE_ENV === "staging";

export const isProduction = () =>
  window.location.hostname === "budget4you.pl" || process.env.NODE_ENV === "production";

export const parseGraphQLQueryError = (error: GraphQLError) => {
  const err = JSON.parse(JSON.stringify(error));
  console.warn(err);
  return err.networkError?.result.errors;
};

type TFunc = () => void;

export const compose =
  (...functions: TFunc[]) =>
  (args: TFunc) =>
    // @ts-ignore
    functions.reduceRight((arg, fn) => fn(arg), args);

export const graphQlError = (err: GraphQLError | ApolloError) => {
  Notifications.show({
    message: <div>{JSON.stringify(err)}</div>,
    intent: Intent.WARNING,
  });
  throw err;
};

export const DeviceWidthObject: Record<string, { max: number; min: number }> = {
  MobileSmall: { max: 320, min: 0 },
  MobileMedium: { max: 375, min: 321 },
  MobileLarge: { max: 767, min: 376 },
  Tablet: { max: 991, min: 768 },
  LaptopSmall: { max: 1024, min: 992 },
  LaptopLarge: { max: 1440, min: 1025 },
  LargerThanLaptop: { max: 2560, min: 1441 },
  LargeScreenMax: { max: 999999, min: 2561 },
};

type TBuildDeviceDetails = {
  deviceType: string;
  deviceTypeVariant: string;
  orientation: string;
  width: number;
  height: number;
  isFallback: boolean;
};

export const getDeviceTypeInfo = () => {
  const { width, height } = getWindowDimension();
  const buildDeviceDetails: TBuildDeviceDetails = {
    deviceType: "",
    deviceTypeVariant: "",
    orientation: "Portrait",
    width,
    height,
    isFallback: false,
  };
  //  Edge case
  const hasEdgeCase = handleExceptions(buildDeviceDetails, width, height);
  if (hasEdgeCase) {
    return hasEdgeCase;
  }
  if (height < width) {
    // Orientation is landscape
    buildDeviceDetails.orientation = "Landscape";

    if (height <= IdMobileHeight.mobileLandscape_max) {
      // Mobile (landscape)
      buildDeviceDetails.deviceType = "Mobile";
      for (const devc in DeviceWidthObject) {
        if (height <= DeviceWidthObject[devc].max && height >= DeviceWidthObject[devc].min) {
          buildDeviceDetails.deviceTypeVariant = devc;
          break;
        }
      }
    } else if (
      width <= IdDeviceBreakpointsByWidth.tablet_max &&
      width >= IdDeviceBreakpointsByWidth.tablet_min
    ) {
      // Tablet (landscape)
      buildDeviceDetails.deviceType = "Tablet";
      buildDeviceDetails.deviceTypeVariant = "Tablet";
    } else if (
      width <= IdDeviceBreakpointsByWidth.laptop_max &&
      width >= IdDeviceBreakpointsByWidth.laptop_min
    ) {
      // Laptop (landscape)
      buildDeviceDetails.deviceType = "Laptop";

      for (const devc in DeviceWidthObject) {
        if (width <= DeviceWidthObject[devc].max && width >= DeviceWidthObject[devc].min) {
          buildDeviceDetails.deviceTypeVariant = devc;
          break;
        }
      }
    } else if (width > IdDeviceBreakpointsByWidth.laptop_max) {
      // Larger than Laptop (landscape)
      buildDeviceDetails.deviceType = "LargerThanLaptop";

      for (const devc in DeviceWidthObject) {
        if (width <= DeviceWidthObject[devc].max && width >= DeviceWidthObject[devc].min) {
          buildDeviceDetails.deviceTypeVariant = devc;
          break;
        }
      }
    } else {
      // TODO: UNKNOWN realm
      buildDeviceDetails.deviceType = "Mobile";
      buildDeviceDetails.deviceTypeVariant = "MobileLarge";
      buildDeviceDetails.isFallback = true;
    }

    return buildDeviceDetails;
  } else {
    // Orientation is portrait
    buildDeviceDetails.orientation = "Portrait";

    for (const devc in DeviceWidthObject) {
      if (width <= DeviceWidthObject[devc].max && width >= DeviceWidthObject[devc].min) {
        buildDeviceDetails.deviceTypeVariant = devc;
        break;
      }
    }

    if (
      width <= IdDeviceBreakpointsByWidth.laptop_max &&
      width >= IdDeviceBreakpointsByWidth.laptop_min
    ) {
      buildDeviceDetails.deviceType = "Laptop";
    }

    if (
      width <= IdDeviceBreakpointsByWidth.tablet_max &&
      width >= IdDeviceBreakpointsByWidth.tablet_min
    ) {
      buildDeviceDetails.deviceType = "Tablet";
    }
    if (width <= IdDeviceBreakpointsByWidth.mobile_max) {
      buildDeviceDetails.deviceType = "Mobile";
    }

    if (width > IdDeviceBreakpointsByWidth.laptop_max) {
      buildDeviceDetails.deviceType = "LargerThanLaptop";
    }

    return buildDeviceDetails;
  }
};

const handleExceptions = (
  buildDeviceDetails: TBuildDeviceDetails,
  width: number,
  height: number
) => {
  //  iPadPro
  if (width === 1024 && height === 1366) {
    buildDeviceDetails.deviceType = "Tablet";
    buildDeviceDetails.deviceTypeVariant = "iPadPro";
    buildDeviceDetails.orientation = "Portrait";

    return buildDeviceDetails;
  } else if (width === 1366 && height === 1024) {
    //  Edge case
    buildDeviceDetails.deviceType = "Tablet";
    buildDeviceDetails.deviceTypeVariant = "iPadPro";
    buildDeviceDetails.orientation = "Landscape";

    return buildDeviceDetails;
  }

  return undefined;
};

export const IdDeviceBreakpointsByWidth = {
  laptop_max: 1440,
  laptop_min: 992,
  tablet_min: 768,
  tablet_max: 991,
  mobile_max: 767,
  default_min: 768, // Unrecognized device
};

export const IdMobileHeight = {
  mobileLandscape_min: 320,
  mobileLandscape_max: 425,
};

export const getWindowDimension = () => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { width, height };
};

export const updateUserSettingsObject = (
  settings: Record<string, unknown>,
  key: string,
  value: unknown
) => {
  const currentSettings = _.isString(settings) ? JSON.parse(settings) : settings;
  return _.extend({}, currentSettings, { [key]: value });
};

export const isDateTime = (date: DateTime | string): date is DateTime => {
  return date instanceof DateTime;
};

export const parseDate = (date: DateTime | string): DateTime => {
  return isDateTime(date) ? date : DateTime.fromJSDate(new Date(date));
};

export const isString = (value: string | Record<string, unknown>): value is string => {
  return _.isString(value);
};

export const getUserSettings = (
  value: string | Record<string, unknown>
): Record<string, unknown> => {
  return isString(value) ? JSON.parse(value as string) : value;
};

export const prepareNewDate = (
  date?: DateTime | string | undefined | null,
  controlTime: DateTime = DateTime.now()
) => {
  if (!date) return DateTime.now();
  const newDate = parseDate(date);
  return newDate.set({
    second: controlTime.get("second"),
    minute: controlTime.get("minute"),
    hour: controlTime.get("hour"),
  });
};

function hex(c: string) {
  const s = "0123456789abcdef";
  let i = parseInt(c);
  if (i === 0 || isNaN(i)) return "00";
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
}

/* Convert an RGB triplet to a hex string */
export function rgb2hex(rgb: string) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

/* Remove '#' in color hex string */
function trim(s: string) {
  return s.charAt(0) === "#" ? s.substring(1, 7) : s;
}

/* Convert a hex string to an RGB triplet */
function convertToRGB(hex: string) {
  const color = [];
  color[0] = parseInt(trim(hex).substring(0, 2), 16);
  color[1] = parseInt(trim(hex).substring(2, 4), 16);
  color[2] = parseInt(trim(hex).substring(4, 6), 16);
  return color;
}

export function generateColorMap(
  colorStart: string,
  colorMiddle: string,
  colorEnd: string,
  colorCount: number
) {
  // The beginning of your gradient
  const start = convertToRGB(colorStart);
  // The middle of your gradient
  const middle = convertToRGB(colorMiddle);
  // The end of your gradient
  const end = convertToRGB(colorEnd);

  // The number of colors to compute
  const len = Math.ceil(colorCount / 2);

  //Alpha blending amount
  let alpha = 0.0;

  const part1 = [];

  for (let i = 0; i < len; i++) {
    const c = [];

    alpha += 1.0 / len;

    c[0] = start[0] * alpha + (1 - alpha) * middle[0];
    c[1] = start[1] * alpha + (1 - alpha) * middle[1];
    c[2] = start[2] * alpha + (1 - alpha) * middle[2];

    part1.push(rgb2hex(c.toString()));
  }
  part1.reverse();
  alpha = 0;

  const part2 = [];

  for (let i = 0; i < len; i++) {
    const c = [];
    alpha += 1.0 / len;

    c[0] = middle[0] * alpha + (1 - alpha) * end[0];
    c[1] = middle[1] * alpha + (1 - alpha) * end[1];
    c[2] = middle[2] * alpha + (1 - alpha) * end[2];

    part2.push(rgb2hex(c.toString()));
  }

  part2.reverse();
  return part1.concat(part2);
}

export function hex2Hsl(hexColor: string) {
  const r = parseInt(hexColor.substr(1, 2), 16) / 255; // Grab the hex representation of red (chars 1-2) and convert to decimal (base 10).
  const g = parseInt(hexColor.substr(3, 2), 16) / 255;
  const b = parseInt(hexColor.substr(5, 2), 16) / 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h, s;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
}

export function hsl2hex(h: number, s: number, l: number) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getNormalizedValue0ToRange = (
  value: number,
  max: number,
  rangeValue: number
): number => {
  if (max === 0) return 0;
  return ((value - 0) / (Math.abs(max) - 0)) * rangeValue;
};

export const getNormalizedValue = (value: number, min: number, max: number, range: number) => {
  return ((value - min) / (max - min)) * range;
};

export function getContrastYIQ(hexcolor: string) {
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export function getContrast(hexcolor: string) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex: string) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
}

export const isMobile = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

const backendUrl = isTestEnv()
  ? "http://localhost:5000"
  : isStagingEnv()
  ? "https://budget.constexpr.pl/_backend"
  : "https://budget4you.pl/_backend";

export const sendMessage = async ({
  userData,
  name,
  message,
  t,
  onSuccess,
  onFail,
  onFinally,
}: {
  t: TFunction;
  userData: IUser;
  name: string;
  message: string;
  onSuccess: () => void;
  onFail: () => void;
  onFinally: () => void;
}) => {
  try {
    const formData = new FormData();
    formData.append("email", userData?.email as string);
    formData.append("name", name);
    formData.append("text", message);
    formData.append("url", window.location.href);
    formData.append("version", data.version);

    const response = await fetch(`${backendUrl}/send-email`, {
      method: "POST",
      headers: {},
      body: formData,
    });

    if (response.ok) {
      Notifications &&
        Notifications.show({
          message: t("messages.cancel_subscription_request_has_been_sent") as string,
          intent: Intent.WARNING,
        });
      onSuccess();
    } else {
      Notifications &&
        Notifications.show({
          message: t("messages.there_was_an_error_processing_your_request") as string,
          intent: Intent.DANGER,
        });
      onFail();
    }
  } catch (err: unknown) {
    Notifications &&
      Notifications.show({
        message: (
          <div
            dangerouslySetInnerHTML={{
              __html: err ? (err as Error).message : (t("messages.sth_went_wrong") as string),
            }}
          ></div>
        ),
        intent: Intent.DANGER,
      });
    if (err instanceof Error) throw err;
    else throw new Error(err as string);
  } finally {
    onFinally();
  }
};

export const getCategoryLabel = (category?: ICategory): string => {
  return category ? category.name : "";
};

export const isWebViewFromAndroid = () => {
  const userAgent = navigator.userAgent;
  const androidWebViewRegex = /(wv)/i;
  return androidWebViewRegex.test(userAgent);
};

// true
// Mozilla/5.0 (Linux; Android 14; SM-A556B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/127.0.6533.103 Mobile Safari/537.36

// false
// Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Mobile Safari/537.36

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export const downloadBlob = (content: BlobPart, filename: string, contentType: string) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.style.visibility = "hidden";
  document.body.appendChild(pom);
  pom.click();
  document.body.removeChild(pom);
};

export const replacer = (key: string, value: unknown) => {
  // specify how you want to handle null values here
  switch (key) {
    case "account":
    case "category":
      return value && typeof value === "object" && "name" in value ? value.name : "";
    default:
      return value === null ? "" : value;
  }
};

const getDate = (entry: IEntry | IBudgetEntry, key: keyof IEntry | keyof IBudgetEntry): string => {
  return entry[key] ? parseDate(entry[key] as string).toFormat("yyyy-MM-dd") : "";
};

const getNameNameFromNestedObject = (entry: IEntry | IBudgetEntry, key: string) => {
  return entry[key] && typeof entry[key] === "object" && "name" in entry[key]
    ? entry[key].name
    : "";
};

const getEntryValue = (
  entry: IBudgetEntry | IEntry,
  key: string,
  exportOptions: Partial<ExportOptions & ExportTableOptions>
) => {
  switch (key) {
    case "balance":
      return exportOptions.columns?.includes("balance")
        ? (entry.balance as number)?.toFixed(2)
        : "";
    case "value":
      return exportOptions.includeValues || exportOptions.columns?.includes("value")
        ? entry.value.toFixed(2)
        : "";
    case "real_spendings": {
      const entries: Partial<IEntry>[] = [
        ...(entry.category?.entries as IEntry[]),
        ...(_.map(
          entry.category?.splitted_entries || [],
          (splittedEntry: ISplittedEntry): ISplittedEntry => {
            return {
              ..._.omit(splittedEntry, "entry"),
              ...splittedEntry.entry,
            };
          }
        ) as IEntry[]),
      ];
      return exportOptions.includeExpenses ? calculateSpendings(entries).toFixed(2) : "";
    }
    case "date":
    case "created_at":
    case "deleted_at": {
      if (exportOptions && isArray(exportOptions.columns)) {
        return exportOptions.columns.includes(key) ? getDate(entry, key) : "";
      }
      return getDate(entry, key);
    }
    case "payee":
    case "account":
    case "category":
      if (exportOptions && isArray(exportOptions.columns)) {
        exportOptions.columns.includes(key) ? getNameNameFromNestedObject(entry, key) : "";
      }
      return getNameNameFromNestedObject(entry, key);

    default:
      return entry[key];
  }
};

const mapHeaderToLabel = (header: string): string => i18n.t(`labels.${header}`);

export const exportDashboardToCsv = (
  exportOptions: ExportOptions,
  incomes: IIncome[],
  budgetSections: IBudgetSection[]
) => {
  const incomeHeader =
    //@ts-expect-error I know what i'm doing
    ["name", "value", "actual_value"] || _.without(Object.keys(incomes[0]), "__typename");

  const entryHeader = [
    "category",
    "date",
    "created_at",
    "deleted_at",
    "name",
    "value",
    "real_spendings",
  ];
  const budgetSectionsHeader = _.without(
    Object.keys(budgetSections[0]),
    "__typename",
    "id",
    "budget_entries",
    "order_number"
  );

  const incomeHeaderString = incomeHeader.map(mapHeaderToLabel).join(",");
  const incomesRows = incomes.map((row) =>
    incomeHeader.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
  );

  try {
    const csv = _.flatten([
      exportOptions.includeIncomes ? incomeHeaderString : [],
      ...(exportOptions.includeIncomes ? incomesRows : []),
      ...budgetSections.map((budgetSection: IBudgetSection) => {
        return [
          "", // this will create empty row between incomes and budget sections and budget sections below
          budgetSectionsHeader
            .map((fieldName) => JSON.stringify(budgetSection[fieldName], replacer))
            .join(","),
          entryHeader.map(mapHeaderToLabel).join(","),
          ...budgetSection.budget_entries.map((entry) => {
            return entryHeader
              .map((fieldName) =>
                JSON.stringify(getEntryValue(entry, fieldName, exportOptions), replacer)
              )
              .join(",");
          }),
        ].join("\r\n");
      }),
    ]).join("\r\n");

    downloadBlob([csv] as unknown as BlobPart, "budget.csv", "text/csv;charset=utf-8;");
  } catch (err) {
    console.error({ err });
    Sentry.captureException(err);
  }
};

export const ENTRY_HEADER_EXPORT = [
  "account",
  "category",
  "payee",
  "date",
  "description",
  "value",
  "balance",
  "transfer",
];

export const exportEntriesToCsv = (entries: IEntry[], exportOptions: ExportTableOptions) => {
  const headers = ENTRY_HEADER_EXPORT.filter((header) => {
    if (exportOptions.columns) {
      return exportOptions.columns.includes(header);
    }
    return true;
  });
  const entryHeaderString = headers.map(mapHeaderToLabel).join(",");
  try {
    const csv = [
      entryHeaderString,
      ...entries.map((entry) => {
        return entry.splitted_entries
          ?.map((splittedEntry) => {
            return headers
              .map((headerKey) => {
                return JSON.stringify(
                  getEntryValue({ ...entry, ...splittedEntry } as IEntry, headerKey, exportOptions),
                  replacer
                );
              })
              .join(",");
          })
          .join("\r\n");
      }),
    ].join("\r\n");

    downloadBlob([csv] as unknown as BlobPart, "table.csv", "text/csv;charset=utf-8;");
  } catch (err) {
    console.error({ err });
    Sentry.captureException(err);
  }
};
